<template>
  <div class="s-about">
    <div
      id="about"
      class="s-about__anchor"
      aria-hidden="true"
    />

    <img
      id="school-about__star"
      loading="lazy"
      class="s-about__star"
      src="/v2/school/about/star.webp"
      alt="star"
      width="109"
      height="141"
    >
    <img
      id="school-about__square"
      loading="lazy"
      class="s-about__square"
      src="/v2/school/about/square.webp"
      alt="square"
      width="103"
      height="181"
    >

    <div class="s-about__container v2-container">
      <h2 class="s-about__title title-primary">
        {{ title }}
      </h2>

      <p
        class="s-about__descr text-secondary"
        v-html="subtitle"
      />

      <div class="s-about__tabs tabs">
        <div class="tabs__head">
          <button
            v-for="(item, index) in programmsList"
            :key="index"
            :class="['m-btn', 'tabs__btn', {
              'tabs__btn--first' : activeTab === 0 && index === activeTab,
              'tabs__btn--second' : activeTab === 1 && index === activeTab,
              'tabs__btn--third' : activeTab === 2 && index === activeTab,
            }]"
            @click="activeTab = index"
          >
            <span class="tabs__age title-secondary">{{ item.age }}</span>
            класс
          </button>
        </div>

        <div class="tabs__body">
          <div class="tabs__card card">
            <div
              :class="['card__inner', {
                'card__inner--first' : activeTab === 0,
                'card__inner--second' : activeTab === 1,
                'card__inner--third' : activeTab === 2,
              }]"
            >
              <div class="card__left">
                <h3 :class="['card__title title-secondary', { 'semeinoe': semeinoePage }]">
                  {{ activeList.title }}
                </h3>

                <p class="card__description text-secondary">
                  {{ activeList.description }}
                </p>

                <ul class="card__list">
                  <li
                    v-for="(item, index) in activeList.subjList"
                    :key="index"
                    :class="[
                      'card__list-item',
                      {'card__list-item--ceny': cenyPage},
                    ]"
                    @click="openModalBySubject"
                  >
                    {{ item.text }}

                    <span
                      v-if="item.plus"
                      :class="['card__tx-color', {
                        'card__tx-color--second' : activeTab === 1,
                        'card__tx-color--third' : activeTab === 2,
                      }]"
                    >
                      {{ item.plus }}
                    </span>
                  </li>
                </ul>

                <p
                  v-if="!externatPage"
                  class="card__tx-bottom text-secondary"
                >
                  <span v-if="attestaciiPage || semeinoePage">Аттестация раз в триместр:</span>
                  <span v-else-if="vecherneePage">На внеучебных курсах для детей мы:</span>
                  <span v-else>На внеучебных курсах мы:</span>
                </p>

                <ul
                  v-if="!externatPage"
                  class="card__h-list"
                >
                  <li
                    v-for="(hItem, hIndex) in activeList.helpList"
                    :key="hIndex"
                    class="card__h-list-item"
                  >
                    {{ hItem }}
                  </li>
                </ul>
              </div>

              <div class="card__rigth">
                <img
                  loading="lazy"
                  :class="['card__img', { 'card__img--externat': externatPage }]"
                  :src="require(`~/static/v2/school/about/${activeList.imgSrc}.webp`)"
                  alt="student"
                >

                <img
                  loading="lazy"
                  class="card__img card__img--mob"
                  :src="require(`~/static/v2/school/about/${activeList.imgSrc}-mob.webp`)"
                  alt="student"
                >
              </div>
            </div>

            <button
              :class="[
                'card__btn  m-btn m-btn--primary',
                {'card__btn-ceny': cenyPage},
              ]"
              @click="openModal"
            >
              <span
                :class="['card__btn-content m-btn__content m-btn__content--primary', {
                  'card__btn-content--first' : activeTab === 0,
                  'card__btn-content--second' : activeTab === 1,
                  'card__btn-content--third' : activeTab === 2,
                  'card__btn-content--ceny' : cenyPage,
                }]"
              >
                {{ cenyPage ? 'Скачать учебный план' : 'Начать учиться' }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchoolAbout',

  data() {
    return {
      activeTab: 0,
    };
  },

  computed: {
    programmsList() {
      let title57 = 'уроки в\u00A0игровом формате и\u00A0увлекательная домашка';
      if (this.attestaciiPage) title57 = 'Легко подать документы, просто сдать аттестации';
      if (this.vecherneePage) title57 = 'Удобный формат обучения для детей и взрослых';
      if (this.semeinoePage) title57 = 'Обучение по гибкому расписанию с удобной аттестацией';
      if (this.externatPage) title57 = 'уроки в\u00A0игровом формате и\u00A0увлекательная домашка';

      let title89 = 'Упор на профильные предметы и\u00A0подготовку к\u00A0ОГЭ';
      // eslint-disable-next-line max-len,vue/max-len
      if (this.attestaciiPage || this.semeinoePage) title89 = 'Легко сдать ОГЭ\u00A0на\u00A05, быстро\u00A0получить аттестат';
      if (this.vecherneePage) title89 = 'Удобный формат обучения для детей и взрослых';
      if (this.externatPage) title89 = 'Упор на профильные предметы и подготовку к ОГЭ';

      let title1011 = 'Готовимся в комфортном режиме к\u00A0ЕГЭ и\u00A0поступлению';
      // eslint-disable-next-line max-len,vue/max-len
      if (this.attestaciiPage || this.semeinoePage) title1011 = 'Легко сдать ЕГЭ\u00A0на\u00A0100, просто\u00A0поступить в вуз';
      if (this.vecherneePage) title1011 = 'Удобный формат обучения для детей и взрослых';
      if (this.externatPage) title1011 = 'Готовимся в комфортном режиме к ЕГЭ и поступлению';

      return [
        {
          age: '5-7',
          title: title57,
          description: this.attestaciiPage
            ? 'Полный список предметов'
            : 'Материалы соответствуют ФГОС и\u00A0ФООП',
          imgSrc: 'student-5-7',
          subjList: [
            { text: 'Русский' },
            { text: 'Литература' },
            { text: 'Математика' },
            { text: 'Физика - 7кл' },
            { text: 'Английский' },
            { text: 'Испанский' },
            { text: 'Биология' },
            { text: 'География' },
            { text: 'История' },
            { text: 'Обществознание' },
            { text: 'Информатика - 7кл' },
            { text: 'ИЗО' },
            { text: 'Музыка' },
            { text: 'Физ-ра' },
          ],
          helpList: this.attestaciiPage || this.semeinoePage
            ? [
              '18.10.2024 по 18.11.2024 г.',
              '01.12.2024 по 26.12.2024 г.',
              '08.04.2025 по 28.05.2025 г.',
            ]
            : [
              'Помогаем найти хобби и интересы',
              'Учим работать в команде',
              'Работаем со страхами и тревогой',
            ],
        },

        {
          age: '8-9',
          title: title89,
          description: this.attestaciiPage || this.semeinoePage
            ? 'Полный список предметов'
            : 'Обучение, ориентированное на\u00A0интересы и\u00A0способности ученика',
          imgSrc: 'student-8-9',
          subjList: [
            { text: 'Русский +', plus: 'ОГЭ' },
            { text: 'Литература' },
            { text: 'Английский' },
            { text: 'Испанский' },
            { text: 'Математика +', plus: 'ОГЭ' },
            { text: 'Теория вероятности' },
            { text: 'Алгебра' },
            { text: 'Геометрия' },
            { text: 'Информатика' },
            { text: 'География' },
            { text: 'Физика' },
            { text: 'Химия' },
            { text: 'Биология' },
            { text: 'Обществознание' },
            { text: 'История' },
            { text: 'Технология' },
            { text: 'ОБЖ' },
            { text: 'Физ-ра' },
          ],
          helpList: this.attestaciiPage || this.semeinoePage
            ? [
              '18.10.2024 по 18.11.2024 г. (8-9 кл)',
              '01.12.2024 по 26.12.2024 г. (8-9 кл)',
              '04.03.2025 по 26.04.2025 г. (9 кл)',
              '08.04.2025 по 28.05.2025 г. (8 кл)',
            ]
            : [
              'Знакомим с миром профессий',
              'Проводим встречи с\u00A0экспертами из\u00A0разных сфер',
              'Развиваем лидерские качества',
              'Помогаем справляться с эмоциями',
            ],
        },

        {
          age: '10-11',
          title: title1011,
          description: this.attestaciiPage || this.semeinoePage
            ? 'Полный список предметов'
            : 'Делаем выбор профессии осознанным, а процесс обучения размеренным и спокойным',
          imgSrc: 'student-10-11',
          subjList: [
            { text: 'Русский +', plus: 'ЕГЭ' },
            { text: 'Литература' },
            { text: 'Английский' },
            { text: 'Испанский' },
            { text: 'Математика +', plus: 'ЕГЭ' },
            { text: 'Теория вероятности' },
            { text: 'Алгебра' },
            { text: 'Геометрия' },
            { text: 'Информатика' },
            { text: 'География' },
            { text: 'Физика' },
            { text: 'Химия' },
            { text: 'Биология' },
            { text: 'Обществознание' },
            { text: 'История' },
            { text: 'ОБЖ' },
            { text: 'Физ-ра' },
          ],
          helpList: this.attestaciiPage || this.semeinoePage
            ? [
              '18.10.2024 по 18.11.2024 г. (10-11 кл)',
              '01.12.2024 по 26.12.2024 г. (10-11 кл)',
              '04.03.2025 по 26.04.2025 г. (11 кл)',
              '08.04.2025 по 28.05.2025 г. (10 кл)',
            ]
            : [
              'Помогаем выбрать специальность\u00A0и\u00A0вуз',
              'Учим создавать социальные и\u00A0бизнес-проекты',
              'Проводим профильные мастер‑классы с\u00A0экспертами',
              'Определяем цели и приоритеты',
            ],
        },
      ];
    },

    activeList() {
      return this.programmsList[this.activeTab];
    },

    cenyPage() {
      // return this.$route.name === 'online-school-ceny';
      return true;
    },
    attestaciiPage() {
      return this.$route.name === 'online-school-attestacii-i-prikreplenie';
    },
    vecherneePage() {
      return this.$route.name === 'online-school-vechernee-obuchenie';
    },
    semeinoePage() {
      return this.$route.name === 'online-school-semeinoe-obuchenie';
    },
    classPage() {
      return Boolean(this.$route.name.match(/online-school-[0-9]{1,2}-klass/));
    },
    classNumber() {
      return Number(this.$route.name.match(/[0-9]{1,2}/)?.[0]) || 8;
    },
    externatPage() {
      return this.$route.name === 'externat';
    },

    title() {
      if (this.attestaciiPage) {
        return 'Прикрепление и аттестации всегда\u00A0онлайн';
      }

      if (this.cenyPage) {
        return 'УРОКИ И АТТЕСТАЦИИ ВСЕГДА ОНЛАЙН';
      }

      return 'Каждый найдет здесь себя';
    },

    subtitle() {
      if (this.classPage) {
        return `Вся&nbsp;программа обучения для ${this.classNumber} класса в&nbsp;онлайн-школе «Синергия»`;
      }

      if (this.attestaciiPage) {
        return 'Периоды аттестации для 5-11 классов и списки предметов';
      }

      if (this.externatPage) {
        if (this.activeTab === 0) return 'Вся программа обучения для 5–7 классов в онлайн-школе «Синергия»';
        if (this.activeTab === 1) return 'Вся программа обучения для 8–9 классов в онлайн-школе «Синергия»';
        if (this.activeTab === 2) return 'Вся программа обучения для 10–11 классов в онлайн-школе «Синергия»';
      }

      return 'Вся&nbsp;программа обучения в&nbsp;онлайн-школе «Синергия»';
    },
  },

  mounted() {
    if (this.classPage) {
      if (this.classNumber === 8 || this.classNumber === 9) {
        this.activeTab = 1;
      }
      if (this.classNumber === 10 || this.classNumber === 11) {
        this.activeTab = 2;
      }
    }
  },

  methods: {
    openModal() {
      if (this.cenyPage) {
        this.$store.dispatch('modals/toggleLead10');
      } else {
        this.$store.dispatch('modals/toggleLead2');
      }
    },
    openModalBySubject() {
      if (this.cenyPage) {
        this.$store.dispatch('modals/toggleLead10');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.s-about {
  position: relative;

  &__title {
    margin-bottom: 20px;

    text-align: center;

    @include media-down(tablet) {
      margin-bottom: 12px;
    }
  }

  &__descr {
    margin-bottom: 60px;

    text-align: center;

    @include media-down(tablet) {
      margin-bottom: 40px;
    }
  }

  .s-about__star {
    position: absolute;
    right: 0;
    top: -30px;

    @include media-down(laptop) {
      display: none;
    }
  }

  .s-about__square {
    position: absolute;
    left: 0;
    bottom: 30px;

    @include media-down(laptop) {
      display: none;
    }
  }
}

.tabs {
  &__head {
    display: flex;
    justify-content: center;

    margin: 0 auto 20px;

    min-width: 960px;
    width: calc(100% - 80px);

    border-bottom: 2px solid $black;

    @include media-down(laptop) {
      justify-content: flex-start;
      gap: 8px;

      margin: 0 -16px 16px;
      padding: 2px 16px;

      min-width: auto;
      width: calc(100vw - 17px);

      border: none;

      overflow: auto;
    }

    @include media-down(tablet) {
      width: 100vw;
    }
  }

  &__btn {
    position: relative;
    z-index: 1;
    color: inherit;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    width: 320px;
    height: 120px;
    margin-left: -2px;
    background-color: $gray-bg;

    border: 2px dashed $black;
    border-bottom: 2px solid transparent;
    border-radius: 20px 20px 0 0;

    font-size: 35px;
    font-weight: 500;
    line-height: 28px;

    transition: 0.2s ease-out;

    @include media-down(laptop) {
      width: 120px;
      height: 88px;
      margin-left: 0;

      border-bottom: 2px dashed $black;
      border-radius: 16px;

      font-size: 18px;
      line-height: 18px;
    }

    @include media-down(tablet) {
      justify-content: flex-start;
      align-items: flex-start;

      padding: 12px;
    }

    &:not(.tabs__btn--third):not(:hover):last-child {
      border-right: 2px dashed $black;
    }

    @media (hover: hover) {
      &:hover {
        border: 2px solid transparent !important;
        box-shadow: 0 0 0 2px $black;
      }
    }

    &--first {
      z-index: 2;

      border: 2px solid transparent;

      box-shadow: 0 0 0 2px $black;

      background: #fbd277;
    }

    &--second {
      z-index: 2;

      border: 2px solid transparent;

      box-shadow: 0 0 0 2px $black;

      background: $green;
    }

    &--third {
      z-index: 2;

      border: 2px solid transparent;

      box-shadow: 0 0 0 2px $black;

      background: $blue;
    }
  }
}

.card {
  position: relative;

  &__inner {
    min-height: 500px;
    display: flex;
    padding: 40px;

    overflow: hidden;
    border-radius: 20px;
    border: 2px solid $black;
    box-shadow: 4px 4px 0 0 $black;
    //background-size: 514px auto;

    @include media-down(laptop) {
      flex-direction: column;
    }

    @include media-down(tablet) {
      padding: 16px 10px;
    }

    &--first {
      background-image: url('/v2/school/about/grid.svg');
      background-color: #fbd277;

      @include media-down(tablet) {
        background-size: 800px;
      }
    }

    &--second {
      background-image: url('/v2/school/about/grid.svg');
      background-color: $green;

      @include media-down(tablet) {
        background-size: 800px;
      }
    }

    &--third {
      background-image: url('/v2/school/about/grid.svg');
      background-color: $blue;

      @include media-down(tablet) {
        background-size: 800px;
      }
    }
  }

  &__left {
    position: relative;
    z-index: 1;

    flex-shrink: 0;

    width: 715px;

    @include media-down(laptop) {
      max-width: 715px;
      width: 100%;
    }
  }

  &__title {
    margin-bottom: 10px;

    letter-spacing: -1.35px;

    @include media-down(tablet) {
      margin-bottom: 12px;

      letter-spacing: -0.52px;
    }

    &.semeinoe {
      min-width: 853px;

      @include media-down(laptop) {
        min-width: auto;
      }
    }
  }

  &__description {
    margin-bottom: 55px;

    @include media-down(tablet) {
      margin-bottom: 32px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 4px 2px;

    margin-bottom: 80px;

    @include media-down(tablet) {
      margin-bottom: 32px;
    }
  }

  &__list-item {
    padding: 10px 20px;

    width: fit-content;

    border-radius: 50px;
    border: 2px solid $black;

    background: $white-color;

    font-size: 16px;
    font-weight: 700;

    &--ceny {
      cursor: pointer;
    }
  }

  &__tx-bottom {
    margin-bottom: 20px;
  }

  &__tx-color {
    &--second {
      color: #89c053;
    }

    &--third {
      color: #4a88da;
    }
  }

  &__h-list {
    display: grid;
    gap: 10px;

    @include media-down(tablet) {
      margin-bottom: 20px;
    }
  }

  &__h-list-item {
    position: relative;

    padding-left: 38px;

    font-size: 24px;
    font-weight: 500;
    line-height: 31px;

    @include media-down(tablet) {
      padding-left: 24px;

      font-size: 18px;
      line-height: 21px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;

      display: block;

      width: 29px;
      height: 29px;

      background: url('/v2/school/about/star.svg');
      background-size: contain;

      @include media-down(tablet) {
        top: 3px;

        width: 16px;
        height: 16px;
      }
    }
  }

  &__rigth {
    position: relative;

    width: 100%;

    @media only screen and (max-width: $school-desktop) {
      right: -110px;
    }

    @include media-down(laptop) {
      right: 0;

      display: flex;
      justify-content: flex-end;

      max-height: 530px;
    }

    @include media-down(tablet) {
      max-height: none;
    }
  }

  &__img {
    position: absolute;
    right: -40px;
    bottom: -40px;

    max-height: 700px;

    object-fit: cover;
    object-position: bottom;

    @media only screen and (max-width: $school-desktop) {
      max-height: 640px;
    }

    @include media-down(laptop) {
      position: relative;
    }

    @include media-down(tablet) {
      display: none;
    }

    &--externat {
      @include media-up(laptop) {
        max-height: 514px;
      }
    }

    &--mob {
      display: none;

      @include media-down(tablet) {
        right: -10px;

        display: block;

        width: calc(100vw - 8px);
        max-height: none;
        height: 100%;
      }
    }
  }

  &__btn {
    position: absolute;
    bottom: 0;
    left: 50%;

    width: 300px;

    transform: translate(-50%, 45px);

    @include media-down(tablet) {
      width: 256px;
    }

    &-ceny {
      width: 430px;

      @include media-down(tablet) {
        width: 300px;
        z-index: 5;
      }
    }
  }

  &__btn-content {
    padding: 18px 50px 22px 50px;

    width: 300px;

    white-space: nowrap;

    @include media-down(tablet) {
      padding: 13px 50px 18px 50px;

      width: 256px;
    }

    &--first {
      background: #ff7233;
    }

    &--second {
      background: #a8ff53;
    }

    &--third {
      background: #41e8ff;
    }

    &--ceny {
      width: 430px;

      @include media-down(tablet) {
        width: 300px;
      }
    }
  }
}
</style>
