var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-about"},[_c('div',{staticClass:"s-about__anchor",attrs:{"id":"about","aria-hidden":"true"}}),_vm._v(" "),_c('img',{staticClass:"s-about__star",attrs:{"id":"school-about__star","loading":"lazy","src":"/v2/school/about/star.webp","alt":"star","width":"109","height":"141"}}),_vm._v(" "),_c('img',{staticClass:"s-about__square",attrs:{"id":"school-about__square","loading":"lazy","src":"/v2/school/about/square.webp","alt":"square","width":"103","height":"181"}}),_vm._v(" "),_c('div',{staticClass:"s-about__container v2-container"},[_c('h2',{staticClass:"s-about__title title-primary"},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]),_vm._v(" "),_c('p',{staticClass:"s-about__descr text-secondary",domProps:{"innerHTML":_vm._s(_vm.subtitle)}}),_vm._v(" "),_c('div',{staticClass:"s-about__tabs tabs"},[_c('div',{staticClass:"tabs__head"},_vm._l((_vm.programmsList),function(item,index){return _c('button',{key:index,class:['m-btn', 'tabs__btn', {
            'tabs__btn--first' : _vm.activeTab === 0 && index === _vm.activeTab,
            'tabs__btn--second' : _vm.activeTab === 1 && index === _vm.activeTab,
            'tabs__btn--third' : _vm.activeTab === 2 && index === _vm.activeTab,
          }],on:{"click":function($event){_vm.activeTab = index}}},[_c('span',{staticClass:"tabs__age title-secondary"},[_vm._v(_vm._s(item.age))]),_vm._v("\n          класс\n        ")])}),0),_vm._v(" "),_c('div',{staticClass:"tabs__body"},[_c('div',{staticClass:"tabs__card card"},[_c('div',{class:['card__inner', {
              'card__inner--first' : _vm.activeTab === 0,
              'card__inner--second' : _vm.activeTab === 1,
              'card__inner--third' : _vm.activeTab === 2,
            }]},[_c('div',{staticClass:"card__left"},[_c('h3',{class:['card__title title-secondary', { 'semeinoe': _vm.semeinoePage }]},[_vm._v("\n                "+_vm._s(_vm.activeList.title)+"\n              ")]),_vm._v(" "),_c('p',{staticClass:"card__description text-secondary"},[_vm._v("\n                "+_vm._s(_vm.activeList.description)+"\n              ")]),_vm._v(" "),_c('ul',{staticClass:"card__list"},_vm._l((_vm.activeList.subjList),function(item,index){return _c('li',{key:index,class:[
                    'card__list-item',
                    {'card__list-item--ceny': _vm.cenyPage},
                  ],on:{"click":_vm.openModalBySubject}},[_vm._v("\n                  "+_vm._s(item.text)+"\n\n                  "),(item.plus)?_c('span',{class:['card__tx-color', {
                      'card__tx-color--second' : _vm.activeTab === 1,
                      'card__tx-color--third' : _vm.activeTab === 2,
                    }]},[_vm._v("\n                    "+_vm._s(item.plus)+"\n                  ")]):_vm._e()])}),0),_vm._v(" "),(!_vm.externatPage)?_c('p',{staticClass:"card__tx-bottom text-secondary"},[(_vm.attestaciiPage || _vm.semeinoePage)?_c('span',[_vm._v("Аттестация раз в триместр:")]):(_vm.vecherneePage)?_c('span',[_vm._v("На внеучебных курсах для детей мы:")]):_c('span',[_vm._v("На внеучебных курсах мы:")])]):_vm._e(),_vm._v(" "),(!_vm.externatPage)?_c('ul',{staticClass:"card__h-list"},_vm._l((_vm.activeList.helpList),function(hItem,hIndex){return _c('li',{key:hIndex,staticClass:"card__h-list-item"},[_vm._v("\n                  "+_vm._s(hItem)+"\n                ")])}),0):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"card__rigth"},[_c('img',{class:['card__img', { 'card__img--externat': _vm.externatPage }],attrs:{"loading":"lazy","src":require(`~/static/v2/school/about/${_vm.activeList.imgSrc}.webp`),"alt":"student"}}),_vm._v(" "),_c('img',{staticClass:"card__img card__img--mob",attrs:{"loading":"lazy","src":require(`~/static/v2/school/about/${_vm.activeList.imgSrc}-mob.webp`),"alt":"student"}})])]),_vm._v(" "),_c('button',{class:[
              'card__btn  m-btn m-btn--primary',
              {'card__btn-ceny': _vm.cenyPage},
            ],on:{"click":_vm.openModal}},[_c('span',{class:['card__btn-content m-btn__content m-btn__content--primary', {
                'card__btn-content--first' : _vm.activeTab === 0,
                'card__btn-content--second' : _vm.activeTab === 1,
                'card__btn-content--third' : _vm.activeTab === 2,
                'card__btn-content--ceny' : _vm.cenyPage,
              }]},[_vm._v("\n              "+_vm._s(_vm.cenyPage ? 'Скачать учебный план' : 'Начать учиться')+"\n            ")])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }