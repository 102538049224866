import { render, staticRenderFns } from "./SchoolAbout.vue?vue&type=template&id=b99f9ea0&scoped=true"
import script from "./SchoolAbout.vue?vue&type=script&lang=js"
export * from "./SchoolAbout.vue?vue&type=script&lang=js"
import style0 from "./SchoolAbout.vue?vue&type=style&index=0&id=b99f9ea0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b99f9ea0",
  null
  
)

export default component.exports